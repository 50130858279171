import * as React from 'react';
import styles from './style.module.css';
interface IActive {
  index: number;
}
interface IWeek {
  id: string;
  value: string;
  status: string;
  title: string;
  endingDate: Date;
  startingDate: Date;
  eventCount: string;
}

const WeeksViewer: React.FC<{
  weeks: Array<IWeek>;
  onSelectWeek: Function;
  selected?: IWeek;
  gridColumns: number;
}> = ({ weeks, onSelectWeek, selected, gridColumns }) => {
  const [active, setActive] = React.useState<IActive>({ index: 0 });

  React.useEffect(() => {
    if (selected) {
      setActive({ index: weeks.indexOf(selected) });
    }
  }, [selected, weeks]);

  const updateActiveValue = (index: number, selectedWeek: IWeek) => {
    /**
     * Set internal state
     * to display current selected week
     * */
    setActive({ index });

    /**
     * Return selected value outside component */
    onSelectWeek(selectedWeek);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    let newIndex = active.index;

    // Left
    if (e.keyCode === 37)
      newIndex = active.index > 0 ? active.index - 1 : weeks.length - 1;

    // Up
    if (e.keyCode === 38 && active.index > gridColumns - 1)
      newIndex = active.index - gridColumns;

    // Right
    if (e.keyCode === 39)
      newIndex = active.index < weeks.length - 1 ? active.index + 1 : 0;

    // Down
    if (e.keyCode === 40 && active.index < weeks.length - gridColumns)
      newIndex = active.index + gridColumns;

    updateActiveValue(newIndex, weeks[newIndex]);
  };

  const handleOnWeekClick = (index: number, week: IWeek) => {
    updateActiveValue(index, week);
  };

  return (
    <div className={styles.weekViewer__container}>
      <div
        className={styles.resumeViewer__grid}
        style={{ gridTemplateColumns: `repeat(${gridColumns}, 20px)` }}
      >
        {weeks.map((item: any, index: any) => (
          <div
            key={index}
            className={`${styles[item.status]}${
              index === active.index ? ` ${styles.active}` : ''
            }`}
            tabIndex={0}
            title={item.title}
            onClick={e => {
              e.preventDefault();
              handleOnWeekClick(index, item);
            }}
            onKeyDown={handleKeyDown}
          >
            {item.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeksViewer;
